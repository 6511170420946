import React, { useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import Logo from "../images/aist_logo.png"
import LogoIcon from "../images/logo_icon.svg"
import Thumbnail from "../images/tinyman.png"
import { Link } from "gatsby"
import Marquee from "react-fast-marquee";
import BrandPopup from "../components/brandPopup"

export default function Header(props) {

  const [isOpen, setIsOpen] = useState(false);
 
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSticky(window.pageYOffset > 400)
      );
    }
  }, []);

  return (
  <>
    <Helmet>
      <title>Aist Tech</title>
      <meta charset="utf-8"></meta>
      <meta name="description" content="Добро пожаловать в Аист Тех! Эволюция службы поддержки на вашем веб-сайте начинается здесь" />
      <meta property="og:image" content={Logo} />
    </Helmet>

    <div class="sliding-text">
      <ul>

      </ul>
    </div>
  <div class="static-header">
    <header>
    <Link className="logo" to="/" onContextMenu={togglePopup} id="logo">
        <img className="logo_img" src={Logo} alt="Aist"/>
      </Link>
      <ul class="menu">
        <li><Link to="/soon">Для бизнеса</Link></li>
        <li><Link to="https://aist-tech.gitbook.io/aist-tech-docs/o-kompanii/dokumentaciya">Документация</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
            Сообщество
          </button>
          <ul class="dropdown">
            <li><a href="https://t.me/aisttech" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="https://medium.com/@aisttechco" target="_blank" rel="noreferrer">Блог</a></li>
          </ul>
        </li>
        <li class="gotoapp">
        <Link to="https://t.me/aist_chatbot" class="mainnet_btn" target="_blank" rel="noreferrer">β версия</Link></li>
      </ul>
    </header>
  </div>
  <div className={`fixed-header ${
          sticky ? "sticky" : ""
        }`}>
    <header>
      <Link className="mini_logo" to="/" onContextMenu={togglePopup} id="">
        <img className="logo_img_m" src={Logo} alt="Aist"/>
      </Link>
      <ul class="menu">
      <li><Link to="/soon">Для бизнеса</Link></li>
        <li><Link to="https://aist-tech.gitbook.io/aist-tech-docs/o-kompanii/dokumentaciya">Документация</Link></li>
        <li class="dropdown_menu">
          <button class="dropdown_main">
          Сообщество
          </button>
            <ul class="dropdown">
            <li><a href="https://t.me/aisttech" target="_blank" rel="noreferrer">Telegram</a></li>
            <li><a href="https://medium.com/@aisttechco" target="_blank" rel="noreferrer">Блог</a></li>
            </ul>
        </li>
        <li>
          
        <Link to="/soon" class="mainnet_btn" target="_blank" rel="noreferrer"><span>β версия</span></Link></li>
      </ul>
    </header>
  </div>
  {isOpen && <BrandPopup handleClose={togglePopup} />}

  </>
  )
}
